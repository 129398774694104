import React from "react"
import { ColoredSpan } from "../ColoredSpan"
import {
  Container,
  ImageWrapper,
  Description,
  Header,
  SubHeader,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const AboutUsHeading = () => {
  return (
    <Container>
      <Header>
        About <ColoredSpan text="us" color="blue" />
      </Header>
      <ImageWrapper>
        <StaticImage
          src="../../images/about-us-image.png"
          alt="logo"
          placeholder="blurred"
          height="325"
        />
      </ImageWrapper>
      <SubHeader>
        We have been here <ColoredSpan text="since 2012" color="green" />
      </SubHeader>
      <Description>
        We offer support in projects, code analysis, developing web and mobile
        apps, DevOps services and cloud solutions. Our goal is to create quality
        software using the latest stack and tools. All this in a great
        atmosphere and a high level of work culture – we are a geaks of sport
        and Java.
      </Description>
    </Container>
  )
}
