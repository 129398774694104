import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1400px + 8vw);
  padding: 0 8vw;
  @media (max-width: 890px) {
    margin: 0 auto 60px;
    padding: 0 8vw;
  }
`

export const HorizontalSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 120px;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 890px) {
    margin-bottom: 60px;
    margin-top: 30px;
    gap: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ImageWrapper = styled.div`
  margin-bottom: 24px;
`

export const Element = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
