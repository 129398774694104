import React from "react"
import { ColoredSpan } from "../ColoredSpan"
import {
  Container,
  ImagesWrapper,
  PictureWrapper,
  Header,
  LinkedInLogo,
  LinkedInContainer,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const AboutUsMeet = () => {
  return (
    <Container>
      <div>
        <Header>
          Meet our <ColoredSpan text="team" color="pink" />
        </Header>
        <LinkedInContainer>
          <h3>Find us on </h3>
          <LinkedInLogo href="https://www.linkedin.com/company/oakfusion/">
            <StaticImage
              src="../../images/linked-in-logo.png"
              alt="logo"
              placeholder="blurred"
            />
          </LinkedInLogo>
        </LinkedInContainer>
      </div>
      <ImagesWrapper>
        <StaticImage
          src="../../images/meet-our-team-background.png"
          alt="logo"
          placeholder="blurred"
        />
        <PictureWrapper>
          <StaticImage
            src="../../images/meet-our-team-picture.jpg"
            alt="logo"
            placeholder="blurred"
          />
        </PictureWrapper>
      </ImagesWrapper>
      <LinkedInContainer mobile>
        <h3>Find us on </h3>
        <LinkedInLogo href="https://www.linkedin.com/company/oakfusion/">
          <StaticImage
            src="../../images/linked-in-logo.png"
            alt="logo"
            placeholder="blurred"
          />
        </LinkedInLogo>
      </LinkedInContainer>
    </Container>
  )
}
