import styled, { css } from "styled-components"

export const Container = styled.div`
  margin: 0 auto;
  max-width: calc(1400px + 8vw);
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 25% 70%;
  gap: 5%;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 8vw;
  }
`

export const Header = styled.h2`
  padding: 12px 0 32px;
`

export const ImagesWrapper = styled.div`
  position: relative;
`
export const PictureWrapper = styled.div`
  position: absolute;
  left: 20%;
  top: 16%;
  @media (max-width: 890px) {
    left: 20%;
    right: 5%;
  }
`
export const LinkedInContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  ${(props) =>
    props.mobile &&
    css`
      display: none;
    `}
  @media (max-width: 890px) {
    display: none;
    margin: 26px auto 0;
    ${(props) =>
      props.mobile &&
      css`
        display: flex;
      `}
  }
`
export const LinkedInLogo = styled.a`
  padding-bottom: 16px;
  @media (max-width: 890px) {
    padding-bottom: 6px;
    width: 100px;
  }
`
