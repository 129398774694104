import React from "react"
import { ColoredSpan } from "../ColoredSpan"
import { StaticImage } from "gatsby-plugin-image"
import { Container, HorizontalSection, Element, ImageWrapper } from "./style"

export const AboutUsWhatWeDo = () => {
  return (
    <Container>
      <h2>
        We love what <ColoredSpan text="we do" color="red" />
      </h2>
      <HorizontalSection>
        <Element>
          <ImageWrapper>
            <StaticImage
              src="../../images/presentation.png"
              alt="logo"
              placeholder="blurred"
            />
          </ImageWrapper>
          <p>Continuous improvement on the art of programing</p>
        </Element>
        <Element>
          <ImageWrapper>
            <StaticImage
              src="../../images/brainstorm.png"
              alt="logo"
              placeholder="blurred"
            />
          </ImageWrapper>
          <p>Oak solid work</p>
        </Element>
        <Element>
          <ImageWrapper>
            <StaticImage
              src="../../images/management.png"
              alt="logo"
              placeholder="blurred"
            />
          </ImageWrapper>
          <p>Adaptability & customize</p>
        </Element>
        <Element>
          <ImageWrapper>
            <StaticImage
              src="../../images/approve.png"
              alt="logo"
              placeholder="blurred"
            />
          </ImageWrapper>
          <p>Transparency</p>
        </Element>
      </HorizontalSection>
      <p>
        In each project you will be working in agile methodology and using tools
        like Java, Java SpringBoot, React/Angular and DevOps.
      </p>
    </Container>
  )
}
