import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1400px + 8vw);
  padding: 30px 8vw 0;
  @media (max-width: 890px) {
    margin: 0 auto 60px;
    padding: 0 8vw;
  }
`

export const ImageWrapper = styled.div`
  width: 50%;
  float: right;
  margin: -66px 0 100px;
  @media (max-width: 890px) {
    width: 100%;
    float: none;
    margin: 20px 0;
  }
`

export const Description = styled.p`
  line-height: 32px;
  width: 45%;
  @media (max-width: 890px) {
    width: 100%;
  }
`

export const Header = styled.h1``

export const SubHeader = styled.h3`
  margin-top: 52px;
  margin-bottom: 40px;
  @media (max-width: 890px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`
