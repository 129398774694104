import * as React from "react"
import { Layout } from "../components/Layout"
import { AboutUsHeading } from "../components/AboutUsHeading"
import { AboutUsWhatWeDo } from "../components/AboutUsWhatWeDo"
import { AboutUsMeet } from "../components/AboutUsMeet"
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakTeams - About us</title>
        <meta
          name="description"
          content="Oakteams - create your best software team"
        />
      </Helmet>
      <AboutUsHeading />
      <AboutUsWhatWeDo />
      <AboutUsMeet />
    </Layout>
  )
}

export default AboutUsPage
